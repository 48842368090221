let context;
let voice1Oscillator, voice2Oscillator, lfoOscillator, filter;
let analyser, dataArray, canvas, canvasCtx;
let synthRunning = false;

document.getElementById("startSynth").addEventListener("click", () => {
    if (!context) {
        context = new (window.AudioContext || window.webkitAudioContext)();
        setupVisualizer();
    }

    if (context.state === 'suspended') {
        context.resume().then(() => {
            synthRunning ? stopSynth() : startSynth();
        });
    } else {
        synthRunning ? stopSynth() : startSynth();
    }
});

function startSynth() {
    const baseFrequency = parseFloat(document.getElementById('frequency').value);
    const distance = parseFloat(document.getElementById('distance').value);
    const lfoFreq = parseFloat(document.getElementById('lfo').value);

    filter = createFilter();

    voice1Oscillator = createOscillator(baseFrequency, 0.5);
    voice2Oscillator = createOscillator(baseFrequency + distance, 0.5);

    lfoOscillator = createLFO(lfoFreq);

    voice1Oscillator.gainNode.connect(filter);
    voice2Oscillator.gainNode.connect(filter);
    filter.connect(analyser);
    analyser.connect(context.destination);

    lfoOscillator.gain.connect(voice1Oscillator.oscillator.frequency);
    lfoOscillator.gain.connect(voice2Oscillator.oscillator.frequency);

    voice1Oscillator.oscillator.start();
    voice2Oscillator.oscillator.start();
    lfoOscillator.oscillator.start();

    synthRunning = true;
    visualize();
}

function stopSynth() {
    voice1Oscillator.oscillator.stop();
    voice2Oscillator.oscillator.stop();
    lfoOscillator.oscillator.stop();
    synthRunning = false;
}

function createOscillator(frequency, gainValue) {
    const oscillator = context.createOscillator();
    const gainNode = context.createGain();
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    gainNode.gain.value = gainValue;

    oscillator.connect(gainNode);
    return { oscillator, gainNode };
}

function createLFO(frequency) {
    const oscillator = context.createOscillator();
    const gain = context.createGain();
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    gain.gain.value = 50;

    oscillator.connect(gain);
    return { oscillator, gain };
}

function createFilter() {
    const filter = context.createBiquadFilter();
    filter.type = "lowpass";
    filter.frequency.value = 20000;
    return filter;
}

document.getElementById('frequency').addEventListener('input', function () {
    const baseFrequency = parseFloat(this.value);
    if (voice1Oscillator && voice2Oscillator) {
        const distance = parseFloat(document.getElementById('distance').value);
        voice1Oscillator.oscillator.frequency.setValueAtTime(baseFrequency, context.currentTime);
        voice2Oscillator.oscillator.frequency.setValueAtTime(baseFrequency + distance, context.currentTime);
    }
});

document.getElementById('lfo').addEventListener('input', function () {
    if (lfoOscillator) {
        lfoOscillator.oscillator.frequency.setValueAtTime(this.value, context.currentTime);
    }
});

document.getElementById('filterFreq').addEventListener('input', function () {
    if (filter) {
        const scaledFreq = scaleFrequency(this.value / this.max, 20, 20000);
        filter.frequency.setValueAtTime(scaledFreq, context.currentTime);
    }
});

function setupVisualizer() {
    analyser = context.createAnalyser();
    analyser.fftSize = 256;
    dataArray = new Uint8Array(analyser.frequencyBinCount);

    canvas = document.getElementById('visualizer');
    canvasCtx = canvas.getContext('2d');
}

function visualize() {
    requestAnimationFrame(visualize);

    analyser.getByteFrequencyData(dataArray);
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    canvasCtx.beginPath();
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const blobRadius = 40 + dataArray[0] / 8;

    for (let i = 0; i < dataArray.length; i++) {
        const angle = (i / dataArray.length) * Math.PI * 2;
        const randomShift = Math.random() * 5 - 2.5;
        const x = centerX + Math.cos(angle) * (blobRadius + randomShift);
        const y = centerY + Math.sin(angle) * (blobRadius + randomShift);
        i === 0 ? canvasCtx.moveTo(x, y) : canvasCtx.lineTo(x, y);
    }
    canvasCtx.closePath();
    canvasCtx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    canvasCtx.fill();
    canvasCtx.strokeStyle = '#00ff00';
    canvasCtx.stroke();
}

function scaleFrequency(value, min, max) {
    return Math.pow(2, value * (Math.log2(max) - Math.log2(min)) + Math.log2(min));
}
